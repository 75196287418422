import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"

Vue.use(Vuex)

export default new Vuex.Store({
     state: {
          cmsUrl: process.env.VUE_APP_API_URL,
          token: process.env.VUE_APP_TOKEN,
          screenWidth: null,
          screenBreakXL: 1200,
          screenBreakLG: 992,
          fontSize: 18,
          activeMenuPoint: null,
          navOpen: false,
          previewImage: null,
          // CMS Data
          schoolInfo: {},
          pages: [],
          newsArticles: [],
          events: [],
          cooperations: [],
          teams: [],
     },
     getters: {
          getFontSize: (state) => {
               return state.fontSize
          },
     },
     mutations: {
          updateScreenWidth(state, width) {
               state.screenWidth = width
          },
          updateActiveMenuPoint(state, ID) {
               state.activeMenuPoint = ID
          },
          updateNavOpen(state, status) {
               state.navOpen = status
          },
          setFontSize(state, newSize) {
               state.fontSize = newSize
          },
          setPreviewImage(state, image) {
               state.previewImage = image
          },
          SET_DATA(state, { data, stateName }) {
               Vue.set(state, stateName, data.data)
          },
     },
     actions: {
          setScreenWidth({ commit }) {
               let width = window.innerWidth
               commit("updateScreenWidth", width)
          },
          async fetchData(
               { state, commit },
               { dataType, stateName, filter = "" }
          ) {
               try {
                    const response = await axios.get(
                         `${state.cmsUrl}/api/${dataType}/?populate=*&pagination[pageSize]=250${filter}`,
                         {
                              headers: {
                                   Authorization: `Bearer ${state.token}`,
                              },
                         }
                    )
                    const data = response.data
                    commit("SET_DATA", { data, stateName })
               } catch (error) {
                    console.error("Error fetching data:", error)
                    // Handle error, z.B. durch eine Fehlermeldung anzeigen
               }
          },
          init_DataPull({ dispatch }) {
               // Pull Schoolinfos
               dispatch("fetchData", {
                    dataType: "schoolinfo",
                    stateName: "schoolInfo",
               })

               // Pull Pages
               dispatch("fetchData", {
                    dataType: "pages",
                    stateName: "pages",
               })

               // Pull Cooperations
               dispatch("fetchData", {
                    dataType: "cooperations",
                    stateName: "cooperations",
               })

               // Pull NewsArtikles
               dispatch("fetchData", {
                    dataType: "newsarticles",
                    stateName: "newsArticles",
                    filter: "&sort[0]=publishdate:desc"
               })

               // Pull Teams
               dispatch("fetchData", {
                    dataType: "teams",
                    stateName: "teams",
               })

               // Pull Events
               const startDate = new Date()
               startDate.setDate(startDate.getDate() - 14)
               dispatch("fetchData", {
                    dataType: "events",
                    stateName: "events",
                    filter:
                         "&filters[startdate][$gte]=" +
                         startDate.toISOString() +
                         "&sort[0]=startdate",
               })
          },
     },
     modules: {},
})
